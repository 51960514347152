import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '../../store'
import router from '../../router'

import AuthModel from '../../models/auth.model'
import StorageKeys from '../../utils/storage-keys'
import { getErrorMessage } from '../../utils/errors'

export default function () {
  const toast = useToast()

  const loginValidation = ref(null)

  const userEmail = ref('')
  const password = ref('')
  const remember = ref(false)

  const loading = ref(false)

  /**
   * Shows an error toast on screen.
   *
   * @param {Object} error the error object to be shown.
   */
  const showError = (error) => {
    toast({
      component: ToastificationContent,
      props: {
        title: getErrorMessage(error),
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  }

  /**
   * Signs the user in using its email and password.
   */
  const signIn = async () => {
    loading.value = true

    const success = await loginValidation.value.validate()

    if (!success) {
      return
    }

    const result = await store.dispatch(
      'auth/signIn',
      new AuthModel({ email: userEmail.value, password: password.value }),
    )

    const error = result ? result.error : { statusCode: 500 }

    if (error) {
      loading.value = false
      showError(error)
      return
    }

    localStorage.setItem(StorageKeys.accessToken, result.token)
    localStorage.setItem(StorageKeys.userId, result.user.id)

    store.dispatch('auth/setToken', result.token)
    store.dispatch('auth/setUserId', result.user.id)
    store.dispatch('auth/setUser', result.user)

    loading.value = false

    toast({
      component: ToastificationContent,
      props: {
        title: 'Signed in',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    router.push('/')
  }

  return {
    signIn,
    loginValidation,
    userEmail,
    password,
    remember,
    loading,
  }
}
