<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <!-- <h2 class="brand-text text-primary ml-1">Asa Pettersson</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid alt="Login" :src="imgUrl" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{$t('login.welcome')}}
          </b-card-title>
          <b-card-text class="mb-2">
            {{$t('login.pleaseSignin')}}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group :label="$t('login.inputs.email')" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('login.inputs.email')"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="userEmail"
                    id="login-email"
                    name="login-email"
                    placeholder="example@co.com"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{$t('login.inputs.password')}}</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password-v2' }">
                    <small>{{$t('login.forgotPassword')}}</small>
                  </b-link> -->
                </div>

                <validation-provider
                  #default="{ errors }"
                  :name="$t('login.inputs.password')"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model="password"
                      id="login-password"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="············"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  v-model="remember"
                  id="remember-me"
                  name="checkbox-1"
                >
                  {{$t('login.rememberMe')}}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button block type="submit" variant="primary" @click="signIn">
                <b-spinner v-if="loading" small :label="$t('commons.loading')" />

                <span v-else>{{$t('login.signInBtn')}}</span>
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import store from '@/store/index'

import useLogin from './useLogin'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require('@/assets/images/pages/undraw-for-sale.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/undraw-for-sale.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    const { signIn, loginValidation, userEmail, password, remember, loading } =
      useLogin()

    return {
      signIn,
      loginValidation,
      userEmail,
      password,
      remember,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
